import {Controller} from 'stimulus'
import throttle from 'lodash/throttle'
import Swiper, {EffectFade, Pagination} from 'swiper'

Swiper.use([EffectFade, Pagination])

export default class extends Controller {
  connect() {
    this.handleResize = this.handleResize.bind(this)
    window.addEventListener('resize', throttle(this.handleResize, 250))
    this.initSlider()
  }

  disconnect() {
    window.removeEventListener('resize', throttle(this.handleResize, 250))
    if (this.swiper) {
      this.swiper.destroy(true, true)
    }
  }


  /**
   * Handle the window resize event -
   * This will destroy the slider if it exists, and then re-initialize it depending on the window width.
   * @returns {void}
   */
  handleResize() {
    // if the slider exists, destroy it
    if (this.swiper) {
      this.swiper.destroy(true, true)
    }

    // get the window width
    const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

    // if the window width is less than 700, initialize the slider
    if (windowWidth < 700) {
      // re-initialize the slider
      this.initSlider()
    }
  }

  /**
   * Initialize the slider -
   * This will initialize the slider based on the prescribed configuration.
   */
  initSlider() {
    this.swiper = new Swiper('.js-gallerySlider', {
      slidesPerView: 1,
      centeredSlides: true,
      speed: 800,
      loop: true,
      autoHeight: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
    })
  }
}
